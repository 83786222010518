import { createApp } from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Wysiwyg from '../vue/base/Wysiwyg.vue';
import ButtonAction from '../vue/base/ButtonAction.vue';
import ItemCountSelect from '../vue/base/ItemCountSelect.vue';
import Pagination from '../vue/base/Pagination.vue';
import SortItem from '../vue/base/SortItem.vue';
import ToggleAction from '../vue/base/ToggleAction.vue';
import TigrisDataList from '../vue/TigrisDataList.vue';
import TigrisListTree from '../vue/TigrisListTree.vue';
import FileUpload from '../vue/base/FileUpload.vue';
import TigrisNotification from '../vue/TigrisNotification.vue';
import TigrisChart from '../vue/TigrisChart.vue';
import TigrisDialog from '../vue/TigrisDialog.vue';
import TigrisApp from '../vue/TigrisApp.vue';
import TigrisFileManager from '../vue/TigrisFileManager.vue';
import SwitchTheme from '../vue/SwitchTheme.vue';
import CommandConsole from '../vue/back/CommandConsole.vue';
import AdminMenuExtended from '../vue/back/AdminMenuExtended.vue';
import DynamicListSelect from '../vue/base/DynamicListSelect.vue';
import SecurityButton from '../vue/base/SecurityButton.vue';
import { DatePicker } from 'v-calendar';
import { capitalize, parseIcons, truncate, formatDate } from '../ts/utils';

const vueApp = createApp(TigrisApp);

vueApp.use(VueAxios, axios);
vueApp.component('wysiwyg', Wysiwyg);
vueApp.component('TigrisDataList', TigrisDataList);
vueApp.component('TigrisListTree', TigrisListTree);
vueApp.component('TigrisNotification', TigrisNotification);
vueApp.component('TigrisChart', TigrisChart);
vueApp.component('ButtonAction', ButtonAction);
vueApp.component('ItemCountSelect', ItemCountSelect);
vueApp.component('Pagination', Pagination);
vueApp.component('SortItem', SortItem);
vueApp.component('ToggleAction', ToggleAction);
vueApp.component('FileUpload', FileUpload);
vueApp.component('TigrisDialog', TigrisDialog);
vueApp.component('VDatePicker', DatePicker);
vueApp.component('CommandConsole', CommandConsole);
vueApp.component('AdminMenuExtended', AdminMenuExtended);
vueApp.component('SwitchTheme', SwitchTheme);
vueApp.component('DynamicListSelect', DynamicListSelect);
vueApp.component('TigrisFileManager', TigrisFileManager);
vueApp.component('SecurityButton', SecurityButton);

vueApp.config.globalProperties.axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
vueApp.config.globalProperties.axios.defaults.headers.delete['X-Requested-With'] = 'XMLHttpRequest';
vueApp.config.globalProperties.axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
vueApp.config.globalProperties.axios.defaults.headers.put['X-Requested-With'] = 'XMLHttpRequest';
vueApp.config.globalProperties.axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
vueApp.config.globalProperties.axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
vueApp.config.globalProperties.axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
vueApp.config.globalProperties.iconStyle = iconStyle;
vueApp.config.globalProperties.$filters = {
    capitalize,
    truncate,
    formatDate,
    parseIcons(value) {
        return parseIcons(value, iconStyle);
    },
    currency(value, locale = "fr-FR", currency = "EUR") {

        return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value);
    },
    textColor(hexcolor) {
        let r = parseInt(hexcolor.substr(0, 2), 16);
        let g = parseInt(hexcolor.substr(2, 2), 16);
        let b = parseInt(hexcolor.substr(4, 2), 16);
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        return (yiq >= 128) ? '#000' : '#fff';
    },
    toFixed(value, nbDigid) {
        return Number.parseFloat(value).toFixed(nbDigid);
    },

    trans(value, params) {
        return Tigris.trans(value, params)
    }

}

export { vueApp };