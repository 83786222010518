<script>
import TigrisEvents from '../../js/tigris-events';

export default {
    props: {
        time: {
            type: Number,
            required: false,
            default: 1500,
        },

        helpText: {
            type: String,
            required: false,
            default: 'Maintenez le clic sur le bouton pour envoyer le formulaire.',
        },

        tokenName: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            timeoutId: 0,
            valid: false,
            token: null,
            formElement: null,
            tokenInput: null,
        }
    },

    mounted() {
        this.getSecurityToken();

        this.formElement = this.$refs.button.closest('form');
        this.tokenInput = this.formElement.querySelector('input[name="' + this.tokenName + '"]');
        if (null !== this.tokenInput) {
            this.tokenInput.value = '';
        }

        // Delete original submit button
        const submitButtons = this.formElement.querySelectorAll('button[type="submit"]');
        for (const button of submitButtons) {
            if (button === this.$refs.button) {
                continue;
            }
            button.classList.add('d-none');

            const parentFiedset = button.closest('fieldset');
            if (null !== parentFiedset) {
                parentFiedset.classList.add('d-none');
            }
        }

        // Intercept security reset event
        document.addEventListener(TigrisEvents.SECURITY_BUTTON_RESET, () => {
            this.valid = false;
        });
    },

    methods: {
        mouseDown() {
            this.timeoutId = setTimeout(() => {
                this.valid = true;
                this.sendForm();
            }, this.time);
        },

        mouseUp() {
            clearTimeout(this.timeoutId);
            this.timeoutId = 0;
        },

        getSecurityToken() {
            const url = Routing.generate('tigris_base_app_securitytoken');
            this.$http.get(url + '?key=tigris').then(response => {
                this.token = response.data.token;
                console.log(this.token);
            });
        },

        sendForm() {
            if (null === this.formElement) {
                return;
            }
            
            if (null !== this.tokenInput) {
                this.tokenInput.value = this.token;
            }

            setTimeout(() => {
                if (this.formElement.getAttribute('data-ajax')) {
                    this.formElement.dispatchEvent(new Event('submit'));
                } else {
                    this.formElement.submit();
                }
                

            
            }, 200);
        }
    }

}
</script>

<template>
    <button ref="button" :class="[{ 'click-down': timeoutId !== 0 }, $attrs.class]" v-on:mousedown="mouseDown" v-on:mouseup="mouseUp"
        v-on:mouseleave="mouseUp" @click.prevent :disabled="valid" v-on:touchstart="mouseDown" v-on:touchend="mouseUp" type="submit">
        <svg width="22" height="22" viewBox="0 0 22 22" class="circular-progress" fill="none" fill-rule="evenodd">
            <circle class="bg" v-show="!valid"></circle>
            <circle class="fg" v-show="!valid"></circle>
            <polyline class="check" stroke="#FFFFFF" stroke-width="3" points="2 12 9 18 18 6" v-show="valid" />
        </svg>
        <slot></slot>
    </button>
    <div class="help-text" v-html="helpText"></div>
</template>

<style scoped>
button {
    display: flex;
    align-items: center;
}

.click-down {
    position: relative;
}

.circular-progress {
    --size: 22px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 4px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);

    margin-right: 6px;
}

.click-down .circular-progress {
    animation: progress-animation 5s linear 0s 1 forwards;
    animation-duration: v-bind(time + 'ms');

}

.circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
}

.circular-progress circle.bg {
    stroke: #fdfdfd2d;
}

.circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #ffffffc4;
}

.check {
    animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.1s forwards check;
    stroke-dasharray: 0, 75px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

@property --progress {
    syntax: "<number>";
    inherits: false;
    initial-value: 0;
}

@keyframes progress-animation {
    from {
        --progress: 0;
    }

    to {
        --progress: 100;
    }
}

@keyframes check {
    from {
        stroke-dasharray: 0, 75px;
    }

    to {
        stroke-dasharray: 75px, 75px;
    }
}
</style>